import * as React from "react"
import { FC, useContext } from "react"
import { LoginContext } from "social-supermarket-components"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import GiftingPage from "../../social-supermarket/pages/gifting/GiftingPage"

interface Props {
  data: any
}

const Catalogue: FC<Props> = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Gifting"} description={""} />
      <GiftingPage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Catalogue
